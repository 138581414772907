import { SessionProvider } from 'next-auth/react';
import getConfig from 'next/config';
import Head from 'next/head';
import Script from 'next/script';
import { I18nextProvider } from 'react-i18next';
import { ThemeProvider } from 'styled-components';

import { UnitedGlobalStyle, vndaTheme } from '@olist/united';

import i18n from '@i18n/setup';

import { GlobalStyle } from '@styles/globals';
import theme from '@styles/theme';

import type { AppProps } from 'next/app';

const customTheme = {
  ...vndaTheme,
  breakpoints: theme.breakpoints,
};

function MyApp({ Component, pageProps: { session, ...pageProps } }: AppProps) {
  const { publicRuntimeConfig } = getConfig();
  const { BASE_PATH, GOOGLE_ANALYTICS_ID } = publicRuntimeConfig;

  return (
    <>
      {!!GOOGLE_ANALYTICS_ID && (
        <>
          <Script
            src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_ID}`}
            strategy="afterInteractive"
          />
          <Script id="google-analytics" strategy="afterInteractive">
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
            
              gtag('config', '${GOOGLE_ANALYTICS_ID}');
            `}
          </Script>
        </>
      )}
      <Head>
        <link rel="shortcut icon" href="favicon.ico" />
        <title>Login | Vnda</title>
      </Head>
      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={customTheme}>
          <SessionProvider session={session} basePath={`${BASE_PATH}/api/auth`}>
            <UnitedGlobalStyle />
            <GlobalStyle />
            <Component {...pageProps} />
          </SessionProvider>
        </ThemeProvider>
      </I18nextProvider>
    </>
  );
}

export default MyApp;
